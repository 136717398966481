import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import Layout from '../components/layout'
import SEO from '../components/seo'

const RemoteSupport = ({ intl }) => (
  <Layout>
    <SEO title={intl.formatMessage({ id: 'navigation.remoteSupport' })} />
    <div
      style={{
        maxWidth: `800px`,
        margin: `0 auto`,
      }}
    >
      <form>
        <div className='interfaceRow'>
          <div className='third'>
            <label htmlFor='cloudid'>Cloud ID:</label>
          </div>
          <div className='third inputfields'>
            <input
              className='maxTen'
              type='text'
              placeholder='xxxxxxxxx'
              name='cloudid'
              id='cloudid'
            />
          </div>
          <div className='third textRight'>
            <button>Link</button>
          </div>
        </div>
      </form>
      <div className='interfaceRow'>
        <div className='third'>Client ID:</div>
        <div
          className='third'
          style={{
            paddingLeft: '1rem',
          }}
        >
          xxxxxxxxx
        </div>
      </div>
    </div>
  </Layout>
)

export default injectIntl(RemoteSupport)
